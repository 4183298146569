import React from "react";

export function Balance() {

	return (
		<div>
			<h1>Balance</h1>
		</div>
	)
}



