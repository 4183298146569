import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import { DashboardRoot } from './pages/dashboard/dashboard-root';
import { NotFound } from './pages/not-found';
import { useAuth0 } from "@auth0/auth0-react";
import FirebaseApp, { FirebaseContext } from './core/firebase';
import { OrganisationDTO } from 'vibe-ob-billing-portal-frontend-common';
import { HeaderBar } from './components/header-bar';

function App() {
	const [organisation, setOrganisation] = useState(null as OrganisationDTO | null);
	const { loginWithRedirect, isLoading, isAuthenticated, getAccessTokenSilently, user} = useAuth0();
	const [firebaseApp, setFirebaseApp] = useState(null as FirebaseApp | null);

	useEffect(() => {
		if(!isLoading && !isAuthenticated){
			loginWithRedirect();
		}

		if(!isLoading && isAuthenticated){
			const fetchOrganisation = async () => {
				let token = await getAccessTokenSilently();
				const newFirebaseApp = new FirebaseApp(token)
				setFirebaseApp(newFirebaseApp);

				try {
					const newOrganisation = await newFirebaseApp.fetchOrganisation();
					setOrganisation(newOrganisation);
				} catch (err) {
					console.error(err);
				}
			};
	
			fetchOrganisation();
		}
	}, [isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently])

	return isAuthenticated && organisation && firebaseApp ? (
		<FirebaseContext.Provider value={firebaseApp}>
			<Router>
			<HeaderBar organisation={organisation} user={user} ></HeaderBar>
				<Switch>
					<Route path="/dashboard" exact component={DashboardRoot}></Route>
					<Route path="/dashboard/**" exact component={DashboardRoot}></Route>
					
					
					<Route path="/" render={() => (<Redirect to="/dashboard" />)}/>
					{/* Catch All*/}
					<Route component={NotFound} />
				</Switch>
			</Router>
			<footer className="c-footer">
    			<div className="c-footer__text">Powered by VibePay <span role="img" aria-label="peace-sign">✌️</span></div><img src="images/connects.svg" alt="" className="c-footer__branding" />
			</footer>
		</FirebaseContext.Provider>
		
	) : (<h1>Loading</h1>);
}

export default App;
