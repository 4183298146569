import React from "react";
import { NavLink, useLocation } from "react-router-dom";

type Params = {
	routes: { name: string, path: string, image: string, imageActive: string}[]
}

export function SecondaryBar(params: Params) {
	const location = useLocation();

	return (
		<nav className="c-menu">
			<div className="c-nav">
					{params.routes.map((route) => {
						return <NavLink aria-current="page" className="c-nav__nav-item w-inline-block w--current" exact to={route.path}> 
							<img src={`${process.env.PUBLIC_URL}/${location.pathname === route.path ? route.imageActive : route.image}`} alt="" className="c-nav__icon" />
							<div className="c-nav__label">{route.name}</div>
						</NavLink>
					})}
			</div>
			<a href="refunds.html" className="c-refunds-link w-inline-block">
				<div className="c-refunds-link__total">
					<div className="c-refunds-link__number">7</div>
				</div>
				<div className="c-refunds-link__label">
					<span className="c-refunds-link__heading">Refund requests</span><br />
		 		£147.67
				</div>
				<div className="c-refunds-link__icon"></div>
			</a>
		</nav>
	)
}
