import React from "react";

export function Insights() {

	return (
		<main className="l-main is--full-width">
			<div className="c-filter is--full-width">
				<div data-hover="" data-delay="0" className="c-dropdown w-dropdown">
					<div className="c-dropdown__toggle w-dropdown-toggle">
						<div className="c-dropdown__icon w-icon-dropdown-toggle"></div>
						<div className="c-dropdown__label">June</div>
					</div>
					<nav className="c-dropdown__list w-dropdown-list"><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 1</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 2</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 3</a></nav>
				</div>
				<div className="c-filter__content">
					<h2 className="c-filter__heading">Insights</h2>
				</div>
			</div>
			<div className="l-section is--full-width">
				<div className="l-grid is--small-big">
					<aside className="c-summary">
						<h3 className="c-summary__heading">Spending</h3>
						<div className="c-summary__value">48 verticals</div>
						<div className="c-summary__date">June 2020</div>
						<div className="c-summary__overview">
							<div className="c-summary__icon is--positive"></div>
							<div className="c-summary__text">Your customers spending verticals have <span className="c-summary__highlight is--positive">increased by 22%</span> this month</div>
						</div>
						<div className="c-summary__overview is--last">
							<div className="c-summary__icon is--negative"></div>
							<div className="c-summary__text">Customer spending in your vertical has <span className="c-summary__highlight is--negative">decreased by 22%</span> this month</div>
						</div>
					</aside>
					<div className="l-padding has--border-left">
						<section className="l-flex">
							<div className="c-visual has--margin-right"><img src="images/top-verticals.svg" alt="" className="c-visual__image" /></div>
							<div className="c-breakdown">
								<h4 className="c-heading">Top verticals <span className="c-heading__highlight">Monthly</span></h4>
								<div className="c-breakdown__legend">
									<div className="c-breakdown__item is--red">
										<div className="c-breakdown__icon is--red"></div>
										<div className="c-breakdown__category">Retail</div>
										<div className="c-breakdown__value">25%</div>
									</div>
									<div className="c-breakdown__item is--blue">
										<div className="c-breakdown__icon is--blue"></div>
										<div className="c-breakdown__category">Food &amp; Beverage</div>
										<div className="c-breakdown__value">25%</div>
									</div>
									<div className="c-breakdown__item is--green">
										<div className="c-breakdown__icon is--green"></div>
										<div className="c-breakdown__category">Fitness</div>
										<div className="c-breakdown__value">20%</div>
									</div>
									<div className="c-breakdown__item is--gold">
										<div className="c-breakdown__icon is--gold"></div>
										<div className="c-breakdown__category">Lifestyle</div>
										<div className="c-breakdown__value">18%</div>
									</div>
									<div className="c-breakdown__item is--pink">
										<div className="c-breakdown__icon is--pink"></div>
										<div className="c-breakdown__category">Travel</div>
										<div className="c-breakdown__value">12%</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<div className="l-section is--full-width">
				<div className="l-grid has--4-columns">
					<div className="c-overview">
						<div className="c-overview__visual"><img src="images/daily.svg" alt="" className="c-overview__image" /></div>
						<h6 className="c-overview__heading">£1.1k</h6>
						<div className="c-overview__description">Daily revenue</div>
						<div className="c-overview__difference is--positive">28.5% <span className="c-icon is--positive">Icon</span></div>
					</div>
					<div className="c-overview">
						<div className="c-overview__visual"><img src="images/weekly.svg" alt="" /></div>
						<h6 className="c-overview__heading">£7.8k</h6>
						<div className="c-overview__description">Weekly revenue</div>
						<div className="c-overview__difference is--positive">28.5% <span className="c-icon is--positive">Icon</span></div>
					</div>
					<div className="c-overview">
						<div className="c-overview__visual"><img src="images/monthly.svg" alt="" /></div>
						<h6 className="c-overview__heading">£36k</h6>
						<div className="c-overview__description">Monthly revenue</div>
						<div className="c-overview__difference is--negative">28.5% <span className="c-icon is--negative">Icon</span></div>
					</div>
					<div className="c-overview is--last">
						<div className="c-overview__visual"><img src="images/yearly.svg" alt="" /></div>
						<h6 className="c-overview__heading">£790k</h6>
						<div className="c-overview__description">Yearly revenue</div>
						<div className="c-overview__difference is--positive">28.5% <span className="c-icon is--positive">Icon</span></div>
					</div>
				</div>
			</div>
			<div className="l-section is--full-width">
				<div className="l-grid is--big-small">
					<div className="l-padding has--border-right">
						<section className="l-flex">
							<div className="c-visual has--margin-right"><img src="images/top-verticals.svg" alt="" className="c-visual__image" /></div>
							<div className="c-breakdown">
								<h4 className="c-heading">Demographic %<span className="c-heading__highlight">Monthly</span></h4>
								<div className="c-breakdown__legend">
									<div className="c-breakdown__item is--red">
										<div className="c-breakdown__icon is--red"></div>
										<div className="c-breakdown__category">16-24</div>
										<div className="c-breakdown__value">25%</div>
									</div>
									<div className="c-breakdown__item is--blue">
										<div className="c-breakdown__icon is--blue"></div>
										<div className="c-breakdown__category">25-35</div>
										<div className="c-breakdown__value">25%</div>
									</div>
									<div className="c-breakdown__item is--green">
										<div className="c-breakdown__icon is--green"></div>
										<div className="c-breakdown__category">35-50</div>
										<div className="c-breakdown__value">20%</div>
									</div>
									<div className="c-breakdown__item is--gold">
										<div className="c-breakdown__icon is--gold"></div>
										<div className="c-breakdown__category">Over 50</div>
										<div className="c-breakdown__value">18%</div>
									</div>
									<div className="c-breakdown__item is--pink">
										<div className="c-breakdown__icon is--pink"></div>
										<div className="c-breakdown__category">Under 16</div>
										<div className="c-breakdown__value">12%</div>
									</div>
								</div>
							</div>
						</section>
					</div>
					<aside className="c-summary">
						<h3 className="c-summary__heading">Demographic</h3>
						<div className="c-summary__value">6,247 users</div>
						<div className="c-summary__date">June 2020</div>
						<div className="c-summary__overview">
							<div className="c-summary__icon is--positive"></div>
							<div className="c-summary__text">Your customers spending verticals have <span className="c-summary__highlight is--positive">increased by 22%</span> this month</div>
						</div>
						<div className="c-summary__overview is--last">
							<div className="c-summary__icon"></div>
							<div className="c-summary__text">Customer spending in your vertical has <span className="c-summary__highlight">changed 0%</span> this month</div>
						</div>
					</aside>
				</div>
			</div>
			<div className="l-section is--full-width">
				<div className="l-padding">
					<section className="l-grid is--small-big">
						<aside className="c-summary">
							<h3 className="c-summary__heading has--small-margin">Users &amp;<br />disposable income</h3>
							<ul className="c-legend has--margin">
								<li className="c-legend__key is--red"><span className="c-legend__icon">1</span>Users</li>
								<li className="c-legend__key is--green"><span className="c-legend__icon">1</span>Income</li>
							</ul>
							<p className="c-summary__text">The number of your customers against the amount of disposable income they have each month</p>
						</aside>
						<figure className="c-visual"><img src="images/sales.svg" alt="" className="c-visual__image" /></figure>
					</section>
				</div>
			</div>
			<div className="l-section is--full-width">
				<div className="c-locations">
					<div className="c-map">
						<div className="c-map__pin is--red">
							<div className="c-map__icon is--red"></div>
							<div className="c-map__label">Glasgow <span className="c-map__value">4%</span></div>
						</div>
						<div className="c-map__pin is--gold">
							<div className="c-map__icon is--gold"></div>
							<div className="c-map__label">Carlisle <span className="c-map__value">2%</span></div>
						</div>
						<div className="c-map__pin is--blue">
							<div className="c-map__icon is--blue"></div>
							<div className="c-map__label">Lancaster <span className="c-map__value">8%</span></div>
						</div>
						<div className="c-map__pin is--green">
							<div className="c-map__icon is--green"></div>
							<div className="c-map__label">Manchester <span className="c-map__value">26%</span></div>
						</div>
						<div className="c-map__pin is--aqua">
							<div className="c-map__icon is--aqua"></div>
							<div className="c-map__label">Stoke-on-Trent<span className="c-map__value">18%</span></div>
						</div>
						<div className="c-map__pin is--pink">
							<div className="c-map__icon is--pink"></div>
							<div className="c-map__label">Belfast<span className="c-map__value">6%</span></div>
						</div>
						<div className="c-map__pin is--orange">
							<div className="c-map__icon is--orange"></div>
							<div className="c-map__label">Dublin<span className="c-map__value">3%</span></div>
						</div>
					</div>
					<section className="c-spacer"></section>
					<aside className="c-summary">
						<h3 className="c-summary__heading">Locations</h3>
						<div className="c-summary__value">6,247 users<br />7 locations</div>
						<div className="c-summary__date">June 2020</div><a href="/" className="c-button w-button">View all</a></aside>
				</div>
			</div>
			<div className="l-section is--full-width">
				<div className="l-grid has--2-columns">
					<section className="l-grid__item is--first">
						<h4 className="c-heading">Top merchants<span className="c-heading__highlight">2,450 transactions</span></h4>
						<div className="c-visual"><img src="images/merchants.svg" alt="" /></div>
					</section>
					<section className="l-grid__item is--last">
						<h4 className="c-heading">Direct debits<span className="c-heading__highlight">6,247 users</span></h4>
						<div className="c-visual is--line-chart"><img src="images/direct-debits.svg" alt="" /></div>
						<div className="c-breakdown__legend">
							<div className="c-breakdown__item is--blue">
								<div className="c-breakdown__icon is--blue"></div>
								<div className="c-breakdown__category">Entertainment</div>
								<div className="c-breakdown__value">32%</div>
							</div>
							<div className="c-breakdown__item is--red">
								<div className="c-breakdown__icon is--red"></div>
								<div className="c-breakdown__category">Health &amp; Fitness</div>
								<div className="c-breakdown__value">25%</div>
							</div>
							<div className="c-breakdown__item is--green">
								<div className="c-breakdown__icon is--green"></div>
								<div className="c-breakdown__category">Rent &amp; Mortgage</div>
								<div className="c-breakdown__value">20%</div>
							</div>
							<div className="c-breakdown__item is--gold">
								<div className="c-breakdown__icon is--gold"></div>
								<div className="c-breakdown__category">Transport</div>
								<div className="c-breakdown__value">16%</div>
							</div>
							<div className="c-breakdown__item is--pink">
								<div className="c-breakdown__icon is--pink"></div>
								<div className="c-breakdown__category">Food &amp; Drink</div>
								<div className="c-breakdown__value">2%</div>
							</div>
							<div className="c-breakdown__item is--last">
								<div className="c-breakdown__icon"></div>
								<div className="c-breakdown__category">Other</div>
								<div className="c-breakdown__value">5%</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			<div className="l-foot"><a href="/" className="c-button w-button">Back to top</a></div>
		</main>
	)
}



