import React, { useState, useContext, useEffect, useCallback } from "react";
import Modal from 'react-modal';
import { FirebaseContext } from "../../core/firebase";
import { Link } from "react-router-dom";
import { CustomerDTO, Dates, ScheduleType, BillSetupRequest } from "vibe-ob-billing-portal-frontend-common";

Modal.setAppElement('#root');

const addCustomerModalStyle = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

export function Customers({ match }: any) {
	const firebaseApp = useContext(FirebaseContext);
	const [addCustomerModel, setAddCustomerModel] = useState({
		isOpen: false,
		firstName: "",
		lastName: "",
		email: "",
	})
	const [billCustomerModel, setBillCustomerModel] = useState({
		isOpen: false,
		customer: undefined as undefined | CustomerDTO,
		amount: undefined as undefined | number,
		dueDate: undefined as undefined | Date,
		reference: undefined as undefined | string
	})
	const [customers, setCustomers] = useState([] as CustomerDTO[])

	const fetchCustomers = useCallback(async () => {
		setCustomers(await firebaseApp.fetchCustomers());
	}, [firebaseApp]);

	useEffect(() => {
		fetchCustomers();
	}, [fetchCustomers]);

	function openAddCustomerModal() {
		addCustomerModel.isOpen = true;
		setAddCustomerModel(Object.assign({}, addCustomerModel));
	}

	function closeAddCustomerModal() {
		addCustomerModel.isOpen = false;
		addCustomerModel.firstName = "";
		addCustomerModel.lastName = "";
		addCustomerModel.email = "";
		setAddCustomerModel(Object.assign({}, addCustomerModel));
	}

	async function addCustomer(firstName: string, lastName: string, email: string) {
		await firebaseApp.createCustomer({
			firstName: firstName,
			lastName: lastName,
			email: email
		});

		fetchCustomers();
		closeAddCustomerModal();
	}

	function openBillCustomerModal(customer: CustomerDTO) {
		billCustomerModel.isOpen = true;
		billCustomerModel.customer = customer;
		setBillCustomerModel(Object.assign({}, billCustomerModel));
	}

	function closeBillCustomerModal() {
		billCustomerModel.isOpen = false;
		billCustomerModel.customer = undefined;
		billCustomerModel.amount = undefined;
		setBillCustomerModel(Object.assign({}, billCustomerModel));
	}

	async function billCustomer(amount: number, customer: CustomerDTO, dueDate: Date, reference: string) {
		const billSetupRequest: BillSetupRequest = {
			customerId: customer.id,
			amount: amount,
			dueDate: Dates.toDateString(dueDate),
			reference: reference,
			allowedScheduleTypes: ['single' as ScheduleType]
		};

		await firebaseApp.setupBillForCustomer(billSetupRequest);

		closeBillCustomerModal();
	}

	function handleCustomerModalInput(e: React.FormEvent<HTMLInputElement>) {
		const newAddCustomerModel = Object.assign({}, addCustomerModel);

		switch (e.currentTarget.name) {
			case 'first-name':
				newAddCustomerModel.firstName = e.currentTarget.value;
				break;
			case 'last-name':
				newAddCustomerModel.lastName = e.currentTarget.value;
				break;
			case 'email':
				newAddCustomerModel.email = e.currentTarget.value;
		}

		setAddCustomerModel(newAddCustomerModel);
	}

	function handleBillCustomerModalInput(e: React.FormEvent<HTMLInputElement>) {
		const newAddCustomerModel = Object.assign({}, addCustomerModel);

		switch (e.currentTarget.name) {
			case 'amount':
				billCustomerModel.amount = +e.currentTarget.value;
				break;
			case 'due-date':
				billCustomerModel.dueDate = new Date(e.currentTarget.value);
				break;
			case 'reference':
				billCustomerModel.reference = e.currentTarget.value;
		}

		setAddCustomerModel(newAddCustomerModel);
	}

	return (
		<main className="l-main">
			<section className="l-section">
				<div className="l-full">

					<div className="c-filter"><div data-hover="" data-delay="0" className="c-dropdown w-dropdown">
						<button className="c-dropdown__toggle w-dropdown-toggle" onClick={() => openAddCustomerModal()}>
							<div className="c-dropdown__label">+</div>
						</button>
						<Modal
							isOpen={addCustomerModel.isOpen}
							onRequestClose={closeAddCustomerModal}
							style={addCustomerModalStyle}
							shouldCloseOnOverlayClick={true}
						>
							<h2 className="c-filter__heading">Add Customer</h2>
							<form>
								<label htmlFor="first-name">First Name</label>
								<input type="text" value={addCustomerModel.firstName} name="first-name" id="first-name" onChange={handleCustomerModalInput}></input>

								<label htmlFor="last-name">Last Name</label>
								<input type="text" name="last-name" id="last-name" onChange={handleCustomerModalInput}></input>

								<label htmlFor="email">Email</label>
								<input type="email" name="email" id="email" onChange={handleCustomerModalInput}></input><br />

								<button type="button" onClick={() => addCustomer(addCustomerModel.firstName, addCustomerModel.lastName, addCustomerModel.email)}>Add</button>
							</form>
						</Modal>
						<nav className="c-dropdown__list w-dropdown-list"><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 1</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 2</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 3</a></nav>
					</div>
						<div className="c-filter__content">
							<h2 className="c-filter__heading">Customers</h2>
							<div className="c-filter__nav"><a href="/" className="c-filter__nav-item is--active w-button">All</a><a href="/" className="c-filter__nav-item w-button">New</a><a href="/" className="c-filter__nav-item w-button">Recent</a></div>
						</div>
					</div>
				</div>
			</section>
			<div className="l-section is--last">
				<div className="c-table">
					<nav className="c-table__header">
						<div className="c-table__head">Customer</div>
						<div className="c-table__head">Product</div>
						<div className="c-table__head">Outstanding</div>
						<div className="c-table__head">Total</div>
						<div className="c-table__head">Fee</div>
						<div className="c-table__head">Net</div>
					</nav>
					<div className="c-table__content">
						{customers ? customers.map((customer: CustomerDTO) => {
							return (<nav className="c-table__entry">
								<Link to={{ pathname: `${match.url}/${customer.id}`, state: { customer: customer } }} className="c-profile w-inline-block">
									<div className="c-avatar"><img src="images/profile.jpg" srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w" sizes="47.993621826171875px" alt="" /></div>
									<div className="c-profile__user">
										<div className="c-profile__name">{customer.firstName} {customer.lastName}</div>
										<div className="c-profile__email">{customer.email}</div>
									</div>
								</Link>
								<div className="c-table__text">Jumper</div>
								<div className="c-table__text">£20.20</div>
								<div className="c-table__value is--paid"></div>
								<div className="c-table__text">£20.20</div>
								<div className="c-table__text">£0.20</div>
								<div className="c-table__value is--paid">
								<button className="c-dropdown__toggle w-dropdown-toggle" onClick={() => openBillCustomerModal(customer)}>
									<div className="c-dropdown__label">+</div>
								</button></div>
								<Modal
									isOpen={billCustomerModel.isOpen}
									onRequestClose={closeBillCustomerModal}
									style={addCustomerModalStyle}
									shouldCloseOnOverlayClick={true}
								>
									<h2 className="c-filter__heading">Add Customer</h2>
									<form>
										<label htmlFor="amount">Amount</label>
										<input type="text" value={billCustomerModel.amount} name="amount" id="amount" onChange={handleBillCustomerModalInput}></input>

										<label htmlFor="due-date">Due Date</label>
										<input type="date" name="due-date" id="due-date" onChange={handleBillCustomerModalInput}></input>

										<label htmlFor="reference">Reference</label>
										<input type="email" name="reference" id="reference" onChange={handleBillCustomerModalInput}></input><br />

										<button type="button" onClick={() => billCustomer(billCustomerModel.amount!, billCustomerModel.customer!, billCustomerModel.dueDate!, billCustomerModel.reference!)}>Add</button>
									</form>
								</Modal>
							</nav>
							)
						}) : ""}
					</div>
					<footer className="c-table__footer"><a href="/" className="c-button has--count w-button">3 out of 3 <span role="img" aria-label="thumbs-up">👍</span></a></footer>
				</div>
			</div>
		</main>
	)
}



