import React from "react";

export function NotFound() {
	return (
		<main className="container flex">
			<div className="body center">
				<h1 className="emoji"><span role="img" aria-label="urgh face">😩</span></h1>
				<h1 className="narrow">Oops! We can't seem to find that.</h1>
				<p className="intro">It looks like you may have reached a broken link. Please check you’ve entered it correctly.</p>
				<a className="web-link" href="https://www.vibepay.com">Go to vibepay.com <i className="fa fa-arrow-right"></i></a>
			</div>
		</main>
	)
}