import React, { useState, useEffect, useContext } from "react";
import { CompletedTransactionDetailsDTO } from "vibe-ob-billing-portal-frontend-common";
import { FirebaseContext } from "../../core/firebase";

export function Transaction(props: any) {
	const firebaseApp = useContext(FirebaseContext);
	const [transaction, setTransaction] = useState(props.location?.state?.customer as CompletedTransactionDetailsDTO);

	useEffect(() => {
		async function fetchTransaction() {
			setTransaction(await firebaseApp.fetchCompletedTransaction(props.match.params.id));
		}

		if (!transaction) {
			fetchTransaction();
		}
	}, [firebaseApp, transaction, props]);

	return (
		<>
			{ transaction ? ( 
				<div className="l-main is--full-width">
					<p>id: {transaction.id}</p><br />
					<p>firstName: {transaction.firstName}</p><br />
					<p>lastName: {transaction.lastName}</p><br />
					<p>reference: {transaction.abbreviatedOrderDescription}</p><br />
					<p>payment date: {transaction.paymentDate}</p><br />
					<p>total amount: {transaction.totalAmount}</p><br />
					<p>fee amount: {transaction.feeAmount}</p><br />
					<p>net amount: {transaction.netAmount}</p><br />
					<p>state: {transaction.state}</p><br />
					<p>line Items: {transaction.orderDescription}</p><br />
					<p>payer address: {`${transaction.payerDetails.firstLine}, ${transaction.payerDetails.city}, ${transaction.payerDetails.postcode}`}</p>
				</div>
				) : ("")
			}
		</>
	)
}



