import React from "react";

export function Overview() {

	return (
		<main className="l-main">
		 <section className="l-section">
      <div className="l-full">
        <div className="c-filter">
          <div data-hover="" data-delay="0" className="c-dropdown w-dropdown">
            <div className="c-dropdown__toggle w-dropdown-toggle">
              <div className="c-dropdown__icon w-icon-dropdown-toggle"></div>
              <div className="c-dropdown__label">Today</div>
            </div>
            <nav className="c-dropdown__list w-dropdown-list"><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 1</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 2</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 3</a></nav>
          </div>
          <div className="c-filter__content">
            <h2 className="c-filter__heading">Sales</h2>
            <ul className="c-legend">
              <li className="c-legend__key is--red"><span className="c-legend__icon">1</span>Revenue</li>
              <li className="c-legend__key is--green"><span className="c-legend__icon">1</span>Transactions</li>
            </ul>
            <div className="c-filter__nav"><a href="/" className="c-filter__nav-item is--active w-button">Single</a><a href="/" className="c-filter__nav-item w-button">Compare</a></div>
          </div>
        </div>
        <section className="l-grid is--small-big">
          <aside className="c-summary">
            <h3 className="c-summary__heading">Totals</h3>
            <div className="c-summary__block is--top">
              <h4 className="c-summary__subheading">Revenue</h4>
              <div className="c-summary__value">£1,050.60</div>
              <div className="c-summary__difference is--positive">£300 (28.5%) <span className="c-icon is--positive">Icon</span></div>
            </div>
            <div className="c-summary__block">
              <h4 className="c-summary__subheading">Transactions</h4>
              <div className="c-summary__value">42</div>
              <div className="c-summary__difference is--negative">6 (7.3%) <span className="c-icon is--negative">Icon</span></div>
            </div>
          </aside>
          <figure className="c-visual"><img src="images/sales.svg" alt="" /></figure>
        </section>
      </div>
    </section>
    <section className="l-section">
      <section className="l-grid is--big-small">
        <div className="l-module">
          <div className="c-filter is--small">
            <div data-hover="" data-delay="0" className="c-dropdown w-dropdown">
              <div className="c-dropdown__toggle w-dropdown-toggle">
                <div className="c-dropdown__icon w-icon-dropdown-toggle"></div>
                <div className="c-dropdown__label">Month</div>
              </div>
              <nav className="c-dropdown__list w-dropdown-list"><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 1</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 2</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 3</a></nav>
            </div>
            <div className="c-filter__content">
              <h2 className="c-filter__heading">Recent</h2>
              <div className="c-filter__nav"><a href="/" className="c-filter__nav-item is--active w-button">All</a><a href="/" className="c-filter__nav-item w-button">Refunds</a><a href="/" className="c-filter__nav-item w-button">Failed</a></div>
            </div>
          </div>
          <div className="c-table">
            <div className="c-table__entry has--3-columns">
              <a href="user.html" className="c-profile w-inline-block">
                <div className="c-avatar"><img src="images/profile.jpg" srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w" sizes="47.993621826171875px" alt="" /></div>
                <div className="c-profile__user">
                  <div className="c-profile__name">Jane Cooper</div>
                  <div className="c-profile__text">alma.lawson@example.com</div>
                </div>
              </a>
              <div className="c-table__text">08/06 @ 16:24</div>
              <div className="c-table__double">
                <div className="c-table__value is--paid">£20.00</div>
                <div className="c-table__text">Paid</div>
              </div>
            </div>
            <div className="c-table__entry has--3-columns">
              <a href="user.html" className="c-profile w-inline-block">
                <div className="c-avatar"><img src="images/profile.jpg" srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w" sizes="47.993621826171875px" alt="" /></div>
                <div className="c-profile__user">
                  <div className="c-profile__name">Jane Cooper</div>
                  <div className="c-profile__text">alma.lawson@example.com</div>
                </div>
              </a>
              <div className="c-table__text">08/06 @ 16:24</div>
              <div className="c-table__double">
                <div className="c-table__value">£20.00</div>
                <div className="c-table__text">Refunded</div>
              </div>
            </div>
            <div className="c-table__entry has--3-columns">
              <a href="user.html" className="c-profile w-inline-block">
                <div className="c-avatar"><img src="images/profile.jpg" srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w" sizes="47.993621826171875px" alt=""/></div>
                <div className="c-profile__user">
                  <div className="c-profile__name">Jane Cooper</div>
                  <div className="c-profile__text">alma.lawson@example.com</div>
                </div>
              </a>
              <div className="c-table__text">08/06 @ 16:24</div>
              <div className="c-table__double">
                <div className="c-table__value is--paid">£20.00</div>
                <div className="c-table__text">Paid</div>
              </div>
            </div>
            <div className="c-table__entry has--3-columns">
              <a href="user.html" className="c-profile w-inline-block">
                <div className="c-avatar"><img src="images/profile.jpg" srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w" sizes="47.993621826171875px" alt=""/></div>
                <div className="c-profile__user">
                  <div className="c-profile__name">Jane Cooper</div>
                  <div className="c-profile__text">alma.lawson@example.com</div>
                </div>
              </a>
              <div className="c-table__text">08/06 @ 16:24</div>
              <div className="c-table__double">
                <div className="c-table__value is--failed">£20.00</div>
                <div className="c-table__text">Failed</div>
              </div>
            </div>
            <div className="c-table__entry has--3-columns">
              <a href="user.html" className="c-profile w-inline-block">
                <div className="c-avatar"><img src="images/profile.jpg" srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w" sizes="47.993621826171875px" alt=""/></div>
                <div className="c-profile__user">
                  <div className="c-profile__name">Jane Cooper</div>
                  <div className="c-profile__text">alma.lawson@example.com</div>
                </div>
              </a>
              <div className="c-table__text">08/06 @ 16:24</div>
              <div className="c-table__double">
                <div className="c-table__value is--paid">£20.00</div>
                <div className="c-table__text">Paid</div>
              </div>
            </div>
          </div><a href="/" className="c-button w-button">View all</a></div>
        <div className="l-module">
          <div className="c-widget">
            <div className="c-widget__visual"><img src="images/payments.svg" alt="" className="c-widget__image"/></div>
            <div className="c-widget__icon"></div>
            <h5 className="c-widget__value">84%</h5>
            <div className="c-widget__heading">Successful payment rate</div>
            <div className="c-widget__details">
              <div className="c-widget__block is--first">
                <div className="c-widget__figure">2,587</div>
                <div className="c-widget__label">Payments</div>
                <div className="c-widget__difference is--positive">28.5% <span className="c-icon is--positive">Icon</span></div>
              </div>
              <div className="c-widget__block">
                <div className="c-widget__figure">2,210</div>
                <div className="c-widget__label">Successful</div>
                <div className="c-widget__difference is--negative">5.5% <span className="c-icon is--negative">Icon</span></div>
              </div>
            </div>
          </div><a href="/" className="c-button w-button">View all</a></div>
      </section>
    </section>
    <section className="l-section is--last">
      <div className="l-grid has--4-columns">
        <div className="c-overview">
          <div className="c-overview__visual"><img src="images/daily.svg" alt="" className="c-overview__image" /></div>
          <h6 className="c-overview__heading">£1.1k</h6>
          <div className="c-overview__description">Daily revenue</div>
          <div className="c-overview__difference is--positive">28.5% <span className="c-icon is--positive">Icon</span></div>
        </div>
        <div className="c-overview">
          <div className="c-overview__visual"><img src="images/weekly.svg" alt="" className="c-overview__image"/></div>
          <h6 className="c-overview__heading">£7.8k</h6>
          <div className="c-overview__description">Weekly revenue</div>
          <div className="c-overview__difference is--positive">28.5% <span className="c-icon is--positive">Icon</span></div>
        </div>
        <div className="c-overview">
          <div className="c-overview__visual"><img src="images/monthly.svg" alt="" className="c-overview__image"/></div>
          <h6 className="c-overview__heading">£36k</h6>
          <div className="c-overview__description">Monthly revenue</div>
          <div className="c-overview__difference is--negative">28.5% <span className="c-icon is--negative">Icon</span></div>
        </div>
        <div className="c-overview c-overview__widget--last">
          <div className="c-overview__visual"><img src="images/yearly.svg" alt="" className="c-overview__image"/></div>
          <h6 className="c-overview__heading">£790k</h6>
          <div className="c-overview__description">Yearly revenue</div>
          <div className="c-overview__difference is--positive">28.5% <span className="c-icon is--positive">Icon</span></div>
        </div>
      </div>
    </section>
		</main>
	)
}



