import React, { useContext, useState, useCallback, useEffect } from "react";
import { FirebaseContext } from "../../core/firebase";
import { CompletedTransactionDTO } from "vibe-ob-billing-portal-frontend-common";
import { Link } from "react-router-dom";

export function Transactions({ match }: any) {
	const firebaseApp = useContext(FirebaseContext);

	const [transactions, setTransactions] = useState([] as CompletedTransactionDTO[])

	const fetchTransactions = useCallback(async () => {
		setTransactions(await firebaseApp.fetchCompletedTransactions());
	}, [firebaseApp]);

	useEffect(() => {
		fetchTransactions();
	}, [fetchTransactions]);

	return (
		<main className="l-main">
			<section className="l-section">
				<div className="l-full">
					<div className="c-filter">
						<div data-hover="" data-delay="0" className="c-dropdown w-dropdown">
							<div className="c-dropdown__toggle w-dropdown-toggle">
								<div className="c-dropdown__icon w-icon-dropdown-toggle"></div>
								<div className="c-dropdown__label">June</div>
							</div>
							<nav className="c-dropdown__list w-dropdown-list"><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 1</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 2</a><a href="/" className="c-dropdown__list-item w-dropdown-link">Link 3</a></nav>
						</div>
						<div className="c-filter__content">
							<h2 className="c-filter__heading">Transactions</h2>
							<div className="c-filter__nav"><a href="/" className="c-filter__nav-item is--active w-button">All</a><a href="/" className="c-filter__nav-item w-button">Failed</a><a href="/" className="c-filter__nav-item w-button">Refunds</a></div>
						</div>
					</div>
					<section className="l-grid is--small-big">
						<aside className="c-summary">
							<h3 className="c-summary__heading">Totals</h3>
							<div className="c-summary__value">£32,320.40</div>
							<div className="c-summary__date">June 2020</div>
							<div className="c-summary__overview is--last">
								<div className="c-summary__icon is--positive"></div>
								<div className="c-summary__text">You have a <span className="c-summary__highlight is--positive">22% growth</span> in comparison with previous months</div>
							</div>
						</aside>
						<figure className="c-visual"><img src="images/revenue.svg" alt="" className="c-visual__image" /></figure>
					</section>
				</div>
			</section>
			<div className="l-section is--last">
				<div className="c-table">
					<nav className="c-table__header">
						<div className="c-table__head">Customer</div>
						<div className="c-table__head">Product</div>
						<div className="c-table__head is--active">Date</div>
						<div className="c-table__head">Status</div>
						<div className="c-table__head">Total</div>
						<div className="c-table__head">Fee</div>
						<div className="c-table__head">Net</div>
					</nav>
					<div className="c-table__content">
						{transactions ? transactions.map((transaction: CompletedTransactionDTO) => {
							return (<nav className="c-table__entry">
								<Link to={{ pathname: `${match.url}/${transaction.id}`, state: { transaction: transaction } }} className="c-profile w-inline-block">
									<div className="c-avatar"><img src="images/profile.jpg" srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w" sizes="47.993621826171875px" alt="" /></div>
									<div className="c-profile__user">
										<div className="c-profile__name">{`${transaction.firstName} ${transaction.lastName}`}</div>
										<div className="c-profile__email">{transaction.email}</div>
									</div>
								</Link>
								<div className="c-table__text">{transaction.abbreviatedOrderDescription}</div>
								<div className="c-table__text">{transaction.paymentDate}</div>
								<div className={`c-table__value ${transaction.state === "executed" ? "is--paid" : (transaction.state === "failed" || transaction.state === "cancelled" || transaction.state === "rejected") ? "is--failed" : ""}`}>{transaction.state === "executed" ? "Paid" : transaction.state === "failed" ? "Failed" : "Pending"}</div>
								<div className="c-table__text">{transaction.totalAmount}</div>
								<div className="c-table__text">{transaction.feeAmount}</div>
								<div className="c-table__value is--paid">{transaction.netAmount}</div>
							</nav>
							)
						}) : ""}
					</div>
					<footer className="c-table__footer"><a href="/" className="c-button has--count w-button">3 out of 3 <span role="img" aria-label="thumbs-up">👍</span></a></footer>
				</div>
			</div>
		</main>
	)
}



