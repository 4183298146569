import React from "react";
import { NavLink } from 'react-router-dom';
import { OrganisationDTO } from "vibe-ob-billing-portal-frontend-common";

type Params = {
	organisation: OrganisationDTO,
	user: any
}

export function HeaderBar(params: Params) {

	return (
		<nav className="c-header">
			<h1 className="c-brand">{params.organisation.name}.</h1>
			<div className="c-nav">
				<NavLink exact to="/dashboard" className="c-nav__nav-item">Dashboard</NavLink>
				<NavLink exact to="/settings" className="c-nav__nav-item">Settings</NavLink>
				<NavLink exact to="/help" className="c-nav__nav-item">Help</NavLink>
			</div>
			<div className="c-search w-form">
				<form id="email-form" name="email-form" data-name="Email Form" className="c-search__form">
					<input type="email" className="c-search__input w-input" maxLength={256} name="Search-2" data-name="Search 2" placeholder="Search" id="Search-2" required />
					<input type="submit" value="Submit" data-wait="Please wait..." className="c-search__button w-button" />
				</form>
				<div className="c-search__success w-form-done">
					<div>Thank you! Your submission has been received!</div>
				</div>
				<div className="c-search__error w-form-fail">
					<div>Oops! Something went wrong while submitting the form.</div>
				</div>
			</div>
			<div className="c-profile is--right">
				<div className="c-profile__user is--right"><span className="c-profile__name is--white">{params.user.name}</span><br />{params.user["http://billing.vibepay.com/roles"][0]}</div>
				<div className="c-profile__status"></div>
				<div className="c-avatar is--reversed"><img src={params.user.picture} sizes="47.993621826171875px" alt="" /></div>
			</div>
		</nav>
	)
}
