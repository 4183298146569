import React from "react";
import { NavLink } from "react-router-dom";

type Params = {
	backToRoute: string,
	backToName: string
}

export function BackBar(params: Params) {
	return (
		<div className="c-back">
			<NavLink aria-current="page" href="transactions.html" className="c-back__link w-inline-block" exact to={params.backToRoute}>
				<div className="c-back__icon"></div>
				<div className="c-back__text">Back to {params.backToName}</div>
			</NavLink>
		</div>
	)
}
