import app from 'firebase/app';
import firebase from 'firebase';
import "firebase/firestore";
import "@firebase/functions";
import { 
	PaymentProvider, 
	TransactionSetupRequest, 
	TransactionSetupResult, 
	TransactionResult, 
	ScheduleSetupRequest, 
	ScheduleSetupResult, 
	StandingOrderResult, 
	StandingOrderSetupRequest, 
	StandingOrderSetupResult, 
	StandingOrderStatus, 
	OrganisationDTO, 
	CreateCustomerDTO, 
	CustomerDTO, 
	BillSetupRequest,
	BillDTO,
	CompletedTransactionDTO,
	CompletedTransactionDetailsDTO
} from 'vibe-ob-billing-portal-frontend-common';


const REGION = 'europe-west2';
const PROJECT_ID = 'vibepay-commerce';

const firebaseConfig = {
	apiKey: "AIzaSyDb8P0o9Be6FQAnaSrzaSdxHkvZQpTGx2c",
	authDomain: "vibepay-commerce.firebaseapp.com",
	databaseURL: "https://vibepay-commerce.firebaseio.com",
	projectId: "vibepay-commerce",
	storageBucket: "vibepay-commerce.appspot.com",
	messagingSenderId: "636446781535",
	appId: "1:636446781535:web:650a0069b0090ced4f6bea"
  };

class FirebaseApp {
	functions: app.functions.Functions;
	token: string;

	constructor(token: string) {
		this.token = token;
		app.initializeApp(firebaseConfig);
		this.functions = firebase.app().functions(REGION);

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			this.functions.useFunctionsEmulator('http://localhost:5002');
		} else {
			// production code
		}
	}

	// This is required as the 'httpsCallable' from firebase does not allow for the provision of custom headers
	private httpsCallable = async (functionName: string, body?: any, options?: { args?: {}, headers?: { [key: string]: string }, method?: string }): Promise<any> => {
		let url: string;
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			url = `http://localhost:5002/${PROJECT_ID}/${REGION}/${functionName}`;
		} else {
			url = `https://${REGION}-${PROJECT_ID}.cloudfunctions.net/${functionName}`;
		}

		const response = await fetch(url, {
			method: options?.method,
			headers: options?.headers,
			mode: 'cors',
			body: JSON.stringify({ data: body })
		});

		const contentType = response.headers.get("content-type");
		if (contentType && contentType.indexOf('application/json') !== -1) { // This application only supports json
			return response.json();
		} else {
			return;
		}
	}

	fetchOrganisation = async (): Promise<OrganisationDTO> => {
		let result = await this.httpsCallable('fetchOrganisation', null, { method: 'POST', headers: { "Authorization": `Bearer ${this.token}`, "Content-type": "application/json" } })
		return result.data;
	}

	createCustomer = async (entity: CreateCustomerDTO): Promise<void> => {
		await this.httpsCallable('createCustomer', entity, { method: 'POST', headers: { "Authorization": `Bearer ${this.token}`, "Content-type": "application/json" } });
		return;
	}

	setupBillForCustomer = async (request: BillSetupRequest): Promise<void> => {
		await this.httpsCallable('setupBillForCustomer', request, { method: 'POST', headers: { "Authorization": `Bearer ${this.token}`, "Content-type": "application/json" } });
		return;
	}

	fetchCustomers = async (): Promise<CustomerDTO[]> => {
		let result = await this.httpsCallable('fetchCustomers', null, { method: 'POST', headers: { "Authorization": `Bearer ${this.token}`, "Content-type": "application/json" } });
		return result.data;
	}

	fetchCustomer = async (id: string): Promise<CustomerDTO> => {
		let result = await this.httpsCallable('fetchCustomer', id, { method: 'POST', headers: { "Authorization": `Bearer ${this.token}`, "Content-type": "application/json" } });
		return result.data;
	}

	fetchCompletedTransactions = async (): Promise<CompletedTransactionDTO[]> => {
		let result = await this.httpsCallable('fetchCompletedTransactions', null, { method: 'POST', headers: { "Authorization": `Bearer ${this.token}`, "Content-type": "application/json" } });
		return result.data;
	}

	fetchCompletedTransaction = async (id: string): Promise<CompletedTransactionDetailsDTO> => {
		let result = await this.httpsCallable('fetchCompletedTransaction', id, { method: 'POST', headers: { "Authorization": `Bearer ${this.token}`, "Content-type": "application/json" } });
		return result.data;
	}

	fetchBill = async (id: string): Promise<BillDTO> => {
		var getModel = this.functions.httpsCallable('fetchBill');
		var result = await getModel({ id: id });
		return result.data;
	}

	fetchSinglePaymentProviders = async (): Promise<PaymentProvider[]> => {
		var getModel = this.functions.httpsCallable('fetchSinglePaymentProviders');
		var result = await getModel();
		return result.data;
	}

	fetchStandingOrderPaymentProviders = async (): Promise<PaymentProvider[]> => {
		var getModel = this.functions.httpsCallable('fetchStandingOrderPaymentProviders');
		var result = await getModel();
		return result.data;
	}

	setupTransaction = async (transactionSetupRequest: TransactionSetupRequest): Promise<TransactionSetupResult> => {
		var getModel = this.functions.httpsCallable('setupTransaction');
		var result = await getModel(transactionSetupRequest);
		return result.data;
	}

	setupStandingOrder = async (standingOrderSetupRequest: StandingOrderSetupRequest): Promise<StandingOrderSetupResult> => {
		var getModel = this.functions.httpsCallable('setupStandingOrder');
		var result = await getModel(standingOrderSetupRequest);
		return result.data;
	}

	setupSchedule = async (scheduleSetupRequest: ScheduleSetupRequest): Promise<ScheduleSetupResult> => {
		var getModel = this.functions.httpsCallable('setupSchedule');
		var result = await getModel(scheduleSetupRequest);
		return result.data;
	}

	fetchTransactionResult = async (id: string): Promise<TransactionResult> => {
		var getModel = this.functions.httpsCallable('fetchTransactionResult');
		var result = await getModel(id);
		return result.data;
	}

	qrCodeRedirect = async (id: string, type: string): Promise<string> => {
		var getModel = this.functions.httpsCallable('qrCodeRedirect');
		var result = await getModel({ id: id, type: type });
		return result.data;
	}

	fetchStandingOrderResult = async (id: string): Promise<StandingOrderResult> => {
		var getModel = this.functions.httpsCallable('fetchStandingOrderResult');
		var result = await getModel(id);
		return result.data;
	}

	fetchStandingOrderStatus = async (id: string): Promise<StandingOrderStatus> => {
		var getModel = this.functions.httpsCallable('fetchStandingOrderStatus');
		var result = await getModel(id);
		return result.data;
	}

	signInWithCustomToken = async (token: string): Promise<app.auth.UserCredential> => {
		let result = app.auth().signInWithCustomToken(token);
		return result;
	}
}

export default FirebaseApp;