import React, { useState, useEffect, useContext } from "react";
import { CustomerDTO } from "vibe-ob-billing-portal-frontend-common";
import { FirebaseContext } from "../../core/firebase";

export function Customer(props: any) {
	const firebaseApp = useContext(FirebaseContext);
	const [customer, setCustomer] = useState(props.location?.state?.customer  as CustomerDTO);

	useEffect(() => {
		async function fetchCustomer() {
			setCustomer(await firebaseApp.fetchCustomer(props.match.params.id));
		}

		if (!customer) {
			fetchCustomer();
		}
	}, [firebaseApp, customer, props]);

	return (
		<div className="l-main is--full-width">
			<header className="c-intro">
				<div className="c-avatar is--big"><img src="images/profile.jpg"
					srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w"
					sizes="119.99793243408203px" alt="" /></div>
				<div>
					<h1 className="c-header__heading">{customer.firstName} {customer.lastName}</h1>
					<div className="c-header__text">{customer.email}</div>
				</div>
			</header>
			<div className="l-section is--full-width has--no-border">
				<div className="l-padding">
					<div className="l-grid has--3-columns has--gutters">
						<div className="c-user">
							<h4 className="c-heading has-small-padding">Address<span
								className="c-heading__highlight">Delivery</span></h4>
							<div className="c-summary__overview has--no-margin">
								<div className="c-summary__icon is--address">
									<div className="c-summary__icon-letter">H</div>
								</div>
								<div className="c-summary__text">The Storey,Meeting House Lane, Lancaster, Lancashire, LA1 1AT
							</div>
							</div>
						</div>
						<div className="c-user">
							<h4 className="c-heading has-small-padding">Total spend<span
								className="c-heading__highlight">£367.82</span></h4>
							<div className="c-summary__overview has--no-margin">
								<div className="c-summary__icon is--positive"></div>
								<div className="c-summary__text">Your customers spending verticals have <span
									className="c-summary__highlight is--positive">increased by 22%</span> this month</div>
							</div>
						</div>
						<div className="c-user">
							<h4 className="c-heading has-small-padding">Total transactions<span className="c-heading__highlight">5
								orders</span></h4>
							<div className="c-summary__overview has--no-margin">
								<div className="c-summary__icon is--negative"></div>
								<div className="c-summary__text">Your customers spending verticals have <span
									className="c-summary__highlight is--negative">increased by 22%</span> this month</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="l-section is--full-width has--no-padding">
				<div className="c-filter is--full-width has--padding">
					<div data-hover="" data-delay="0" className="c-dropdown w-dropdown">
						<div className="c-dropdown__toggle w-dropdown-toggle">
							<div className="c-dropdown__label">June</div>
							<div className="c-dropdown__icon w-icon-dropdown-toggle"></div>
						</div>
						<nav className="c-dropdown__list w-dropdown-list"><a href="/"
							className="c-dropdown__list-item w-dropdown-link">Link 1</a><a href="/"
								className="c-dropdown__list-item w-dropdown-link">Link 2</a><a href="/"
									className="c-dropdown__list-item w-dropdown-link">Link 3</a></nav>
					</div>
					<div className="c-filter__content">
						<h2 className="c-filter__heading">Order history</h2>
						<div className="c-filter__nav"><a href="/" className="c-filter__nav-item is--active w-button">All</a><a
							href="/" className="c-filter__nav-item w-button">Failed</a><a href="/"
								className="c-filter__nav-item w-button">Refunds</a></div>
					</div>
				</div>
				<div className="l-grid has--3-columns">
					<div className="l-grid__item has--border-right">
						<div className="c-profile is--stacked">
							<div className="c-avatar is--medium has--small-adding"><img src="images/profile.jpg"
								srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w"
								sizes="95.99417877197266px" alt="" /></div>
							<div className="c-profile__user">
								<div className="c-profile__name is--big">3 items</div>
								<div className="c-profile__email is--big">Ref: /235070923</div>
							</div>
						</div><a href="/" className="c-button is--active has--padding-bottom w-button">Refund £8.70</a>
						<div className="c-breakdown__legend">
							<div className="c-breakdown__item">
								<div className="c-breakdown__category">Date</div>
								<div className="c-breakdown__value">08/06/20</div>
							</div>
							<div className="c-breakdown__item">
								<div className="c-breakdown__category">Bank</div>
								<div className="c-breakdown__value">Starling</div>
								<div className="c-bank__icon is--starling has--margin-left"></div>
							</div>
							<div className="c-breakdown__item is--last">
								<div className="c-breakdown__category">Status</div>
								<div className="c-breakdown__value is--requested">Requested</div>
							</div>
						</div>
					</div>
					<div className="l-grid__item has--border-right">
						<div className="c-profile is--stacked">
							<div className="c-avatar is--medium has--small-adding"><img src="images/profile.jpg"
								srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w"
								sizes="95.99417877197266px" alt="" /></div>
							<div className="c-profile__user">
								<div className="c-profile__name is--big">3 items</div>
								<div className="c-profile__email is--big">Ref: /235070923</div>
							</div>
						</div><a href="/" className="c-button is--deactivated has--margin-bottom w-button">Paid £8.70</a>
						<div className="c-breakdown__legend">
							<div className="c-breakdown__item">
								<div className="c-breakdown__category">Date</div>
								<div className="c-breakdown__value">08/06/20</div>
							</div>
							<div className="c-breakdown__item">
								<div className="c-breakdown__category">Bank</div>
								<div className="c-breakdown__value">Starling</div>
								<div className="c-bank__icon is--starling has--margin-left"></div>
							</div>
							<div className="c-breakdown__item is--last">
								<div className="c-breakdown__category">Status</div>
								<div className="c-breakdown__value is--paid">Paid</div>
							</div>
						</div>
					</div>
					<div className="l-grid__item">
						<div className="c-profile is--stacked">
							<div className="c-avatar is--medium has--small-adding"><img src="images/profile.jpg"
								srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w"
								sizes="95.99417877197266px" alt="" /></div>
							<div className="c-profile__user">
								<div className="c-profile__name is--big">3 items</div>
								<div className="c-profile__email is--big">Ref: /235070923</div>
							</div>
						</div><a href="/" className="c-button is--active has--padding-bottom w-button">Refund £8.70</a>
						<div className="c-breakdown__legend">
							<div className="c-breakdown__item">
								<div className="c-breakdown__category">Date</div>
								<div className="c-breakdown__value">08/06/20</div>
							</div>
							<div className="c-breakdown__item">
								<div className="c-breakdown__category">Bank</div>
								<div className="c-breakdown__value">Starling</div>
								<div className="c-bank__icon is--starling has--margin-left"></div>
							</div>
							<div className="c-breakdown__item is--last">
								<div className="c-breakdown__category">Status</div>
								<div className="c-breakdown__value is--requested">Requested</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="c-table is--hidden">
				<div className="c-filter">
					<div data-hover="" data-delay="0" className="c-dropdown w-dropdown">
						<div className="c-dropdown__toggle w-dropdown-toggle">
							<div className="c-dropdown__label">June</div>
							<div className="c-dropdown__icon w-icon-dropdown-toggle"></div>
						</div>
						<nav className="c-dropdown__list w-dropdown-list"><a href="/"
							className="c-dropdown__list-item w-dropdown-link">Link 1</a><a href="/"
								className="c-dropdown__list-item w-dropdown-link">Link 2</a><a href="/"
									className="c-dropdown__list-item w-dropdown-link">Link 3</a></nav>
					</div>
					<div className="c-filter__content">
						<h2 className="c-filter__heading">Order history</h2>
						<div className="c-filter__nav"><a href="/" className="c-filter__nav-item is--active w-button">All</a><a
							href="/" className="c-filter__nav-item w-button">Failed</a><a href="/"
								className="c-filter__nav-item w-button">Refunds</a></div>
					</div>
				</div>
				<div className="c-table__content">
					<nav className="c-table__entry has--5-columns">
						<div className="c-profile">
							<div className="c-avatar"><img src="images/profile.jpg"
								srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w"
								sizes="100vw" alt="" /></div>
							<div className="c-profile__user">
								<div className="c-profile__name">08/06/20 @ 16:24</div>
								<div>6 products</div>
							</div>
							<div className="c-profile__circle">
								<div className="c-profile__value">6</div>
							</div>
						</div>
						<div className="c-bank">
							<div className="c-bank__icon is--barclays"></div>
							<div>Barclays</div>
						</div>
						<div className="c-table__value is--paid">Paid</div>
						<div className="c-table__value is--paid">£20.00</div>
						<div className="c-table__toggle"></div>
					</nav>
					<nav className="c-table__entry has--5-columns">
						<div className="c-profile">
							<div className="c-avatar"><img src="images/profile.jpg"
								srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w"
								sizes="100vw" alt="" /></div>
							<div className="c-profile__user">
								<div className="c-profile__name">08/06/20 @ 16:24</div>
								<div>6 products</div>
							</div>
							<div className="c-profile__circle">
								<div className="c-profile__value">6</div>
							</div>
						</div>
						<div className="c-bank">
							<div className="c-bank__icon is--starling"></div>
							<div>Starling</div>
						</div>
						<div className="c-table__value">Refunded</div>
						<div className="c-table__value">£0.00</div>
						<div className="c-table__toggle"></div>
					</nav>
					<nav className="c-table__entry has--5-columns">
						<div className="c-profile">
							<div className="c-avatar"><img src="images/profile.jpg"
								srcSet="images/profile-p-500.jpeg 500w, images/profile-p-800.jpeg 800w, images/profile.jpg 3456w"
								sizes="100vw" alt="" /></div>
							<div className="c-profile__user">
								<div className="c-profile__name">08/06/20 @ 16:24</div>
								<div>6 products</div>
							</div>
							<div className="c-profile__circle">
								<div className="c-profile__value">6</div>
							</div>
						</div>
						<div className="c-bank">
							<div className="c-bank__icon is--starling"></div>
							<div>Starling</div>
						</div>
						<div className="c-table__value is--failed">Failed</div>
						<div className="c-table__value is--failed">£10.00</div>
						<div className="c-table__toggle"></div>
					</nav>
				</div>
			</div>
		</div>
	)
}



