import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { SecondaryBar } from "../../components/secondary-bar";
import { Overview } from "./overview";
import { Transactions } from "./transactions";
import { Balance } from "./balance";
import { Insights } from "./insights";
import { BackBar } from "../../components/back-bar";
import { Customer } from "./customer";
import { Customers } from "./customers";
import { Transaction } from "./transaction";

const routes = [
	{ name: "Overview", path: "/dashboard/overview", image: "images/overview--colour.svg", imageActive: "images/overview--colour.svg" },
	{ name: "Transactions", path: "/dashboard/transactions", image: "images/transactions.svg", imageActive: "images/transactions--colour.svg" },
	{ name: "Balance", path: "/dashboard/balance", image: "images/balance.svg", imageActive: "images/balance.svg" },
	{ name: "Customers", path: "/dashboard/customers", image: "images/customers.svg", imageActive: "images/customers.svg" },
	{ name: "Insights", path: "/dashboard/insights", image: "images/insights.svg", imageActive: "images/insights--colour.svg" }
]

export function DashboardRoot() {

	return (
		<Router>
				<Switch>
					<Route path="/dashboard/:subRoute" exact render={() => <SecondaryBar routes={routes} />}></Route>
					<Route path="/dashboard/:subRoute/:id" exact render={(props) => <BackBar backToName={props.match.params.subRoute} backToRoute={`/dashboard/${props.match.params.subRoute}`}/>}></Route>
				</Switch>
				<Switch>
					<Route path="/dashboard/overview" exact component={Overview}></Route>
					<Route path="/dashboard/transactions" exact component={Transactions}></Route>
					<Route path="/dashboard/transactions/:id" exact component={Transaction}></Route>
					<Route path="/dashboard/balance" exact component={Balance}></Route>
					<Route path="/dashboard/customers" exact component={Customers}></Route>
					<Route path="/dashboard/customers/:id" exact component={Customer}></Route>
					<Route path="/dashboard/insights" exact component={Insights}></Route>

					<Route path="/dashboard" render={() => (<Redirect to="/dashboard/overview" />)} />
				</Switch>
		</Router>
	)
}



